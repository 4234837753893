import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'theme-ui';
import Layout from '@solid-ui-layout/Layout';
import Divider from '@solid-ui-components/Divider';
import Header from '@solid-ui-blocks/Header/Block01';
import PrivacyPolicyContent from '@solid-ui-blocks/PrivacyPolicyContent/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import styles from './_styles';
import ContactModal from '@solid-ui-blocks/ContactModal/Block01';
import { Helmet } from 'react-helmet';



const PrivacyPolicy = props => {
    const { allBlockContent } = props.data;
    const content = normalizeBlockContentNodes(allBlockContent?.nodes);

    return (
        <Layout {...props}>
            <Helmet>
                {/* <!-- Primary Meta Tags --> */}
                <title>JUIT | Política de Privacidade</title>
                <meta name="title" content="JUIT | Política de Privacidade" />
                <meta name="description" content="Conheça nossa Política de Privacidade, e quais ações tomamos para proteger sua privacidade e seus dados pessoais." />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="pt-br" />
                <meta name="revisit-after" content="30 days" />
                {/* <!-- Open Graph / Facebook --> */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://juit.io/" />
                <meta property="og:title" content="JUIT | Política de Privacidade" />
                <meta
                    property="og:description"
                    content="Conheça nossa Política de Privacidade, e quais ações tomamos para proteger sua privacidade e seus dados pessoais"
                />
                <meta property="og:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-politica-de-privacidade.png" />
                {/* <!-- Twitter --> */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://juit.io/" />
                <meta property="twitter:title" content="JUIT | Política de Privacidade" />
                <meta
                    property="twitter:description"
                    content="Conheça nossa Política de Privacidade, e quais ações tomamos para proteger sua privacidade e seus dados pessoais."
                />
                <meta property="twitter:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-politica-de-privacidade" />
                <link rel="apple-touch-icon" sizes="180x180" href="../../../site/content/assets/flexiblocks/icons/favIcons/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="../../../site/content/assets/flexiblocks/icons/favIcons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="../../../site/content/assets/flexiblocks/icons/favIcons/favicon-16x16.png" />
                <script type='text/javascript'>
                    {`
                    const isBrowser = typeof window !== 'undefined';
                    const _linkedin_partner_id = '4539113';
                    
                    if (isBrowser) {
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                        (function (l) {
                            if (!l) {
                                window.lintrk = function (a, b) {
                                    window.lintrk.q.push([a, b]);
                                };
                                window.lintrk.q = [];
                            }
                            var s = document.getElementsByTagName('script')[0];
                            var b = document.createElement('script');
                            b.type = 'text/javascript';
                            b.async = true;
                            b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                            s.parentNode.insertBefore(b, s);
                        })(window.lintrk);
                    }
                    `}
                </script>
                <noscript>
                    {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4539113&fmt=gif" />`}
                </noscript>
            </Helmet>
            <ContactModal />
            <Header content={content['header-light']} menuJustify="space-around" />
            <Container variant="full" sx={styles.heroContainer}>
                <Container className="privacypolicycontent" variant="cards.paper-lg" sx={styles.servicesContainer}>
                    <PrivacyPolicyContent />
                </Container>
            </Container>
            <Divider space="5" />
            <Footer content={content['footer']} />
        </Layout>
    );
};

export const query = graphql`
    query innerpageSitePrivacyPolicyBlockContent {
        allBlockContent(filter: { page: { in: ["site/privacypolicy", "shared"] } }) {
            nodes {
                ...BlockContent
            }
        }
    }
`;

export default PrivacyPolicy;
